.assessment-form {
  font-family: Arial, sans-serif;
  margin: 20px;

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .section {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    input[type="checkbox"] {
      margin-right: 10px;
    }

    span {
      font-size: 1rem;
    }
  }

  .assessment-container {
    margin-top: 10px;

    .assessment-header {
      display: flex;
      align-items: center;
      font-weight: bold;
      background: #f0f8ff;
      border: 1px solid #ddd;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 10px;

      span {
        text-align: left;
      }

      .drag-placeholder {
        width: 30px; /* Matches the width of the drag handle */
      }

      .column-title {
        flex: 1; /* Equal flex for the Question column */
      }

      .column-dropdown {
        width: 150px; /* Fixed width for the Mandatory dropdown column */
        text-align: center;
      }

      .column-actions {
        width: 100px; /* Fixed width for action buttons */
        text-align: center;
      }
    }

    .assessment-row {
      display: flex;
      align-items: center;
      gap: 10px;
      // background: #f9f9f9;
      // border: 1px solid #ddd;
      // border-radius: 5px;
      padding: 10px;
      margin-bottom: 10px;

      .drag-handle {
        width: 30px;
        cursor: grab;
        font-size: 1.2rem;
        text-align: center;
      }

      input[type="text"] {
        flex: 1; /* Aligns with the Question column */
        padding: 12px 12px;
        border: 1px solid var(--Neutral-Grey-200, #ECEDF2);;
        border-radius: 5px;
        font-size: 0.9rem;
      }

      select {
        width: 150px; /* Aligns with the Mandatory column */
        padding: 12px 12px;
        border: 1px solid var(--Neutral-Grey-200, #ECEDF2);;
        border-radius: 5px;
        font-size: 0.9rem;
      }

      button {
        // width: 50px; /* Aligns with action column */
        border: none;
        background: none;
        cursor: pointer;
        font-size: 1.2rem;

        &.delete-btn {
          color: #d9534f;
        }

        &.add-btn {
          color: #5cb85c;
        }
      }
    }
  }

  .viva-container {
    margin-top: 10px;

    input[type="text"] {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-right: 10px;
    }

    select {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }
}
