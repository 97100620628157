.badge__container {
  width: 100px;
  height: 100px;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
}

.badge__container img {
  width: auto;
  max-width: 100px;
  height: 84px;
  object-fit: contain;
}

@media screen and (max-width: 576px) {
  .badge__container {
    width: 120px;
    height: 120px;
  }

  .badge__container img {
    height: 88px;
  }
}
