.modal-content{
    .modal-header{
        display: flex;
        justify-content: space-between;
        background-color: #DFF0FA;
        height: 48px;

    }
      .close-modal:hover{
          cursor: pointer;

      }

      .reject-modal-body {
          display: flex;
          justify-content: center;
          flex-direction: column;
          // input{
          //     height: 200px;
          // }
      }
  }