.upload-media-container {
  border: 1px solid black;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 6px;
  padding: 5px;
  .content-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .selected-media-container {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-bottom: 5px;
      flex-wrap: wrap;
      max-height: 500px;
      overflow-y: scroll;
    }
    .button-wrapper {
      display: flex;
      gap: 10px;
    }
  }
  .upload-media:hover {
    cursor: pointer;
  }
}

.progress-bar {
  width: 100px;
  background-color: gray !important;
  border-radius: 5px;
  margin-top: 10px;
  .progress {
    height: 20px;
    background-color: #4caf50 !important;
    border-radius: 5px;
    transition: width 0.3s;
  }
  span {
    display: block;
    text-align: center;
    margin-top: 5px;
    background-color: white;
  }
}

.drap-drop-box {
  min-height: 80px;
  min-width: 500px;
  text-align: center;
  border: 3px dotted black;
  background-color: rgba(168, 168, 168, 0.575);
}

.dotted-container {
  border: 2px dotted #02bfdf;
  background-color: #f6f7f9;
  height: 100px;
}

.upload-modal {
  .modal-content {
    width: 550px;
  }
}
