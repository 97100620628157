.formInput {
  display: flex;
  flex-direction: column;
  // width: 100%;
  input,
  select {
    padding: 6px;
    margin: 10px 0px;
    margin-top: 0;
    border-radius: 5px;
    border: 1px solid gray;
  }

  input[type="file"] {
    //   border: none;
    padding: 6px 4px;
  }

  .checkbox--label {
    margin-left: 1.5rem;
    margin-top: 2.3rem;
  }

  .checkbox--box {
    top: 2.3rem;
    margin: 0;
    left: 1rem;
  }

  .radioOption {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 1rem;

    .radioOptionItem {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      font-weight: bolder;
    }
  }

  label {
    font-size: 12px;
    color: #212529;
    margin-bottom: 10px;
  }

  label[required]:after {
    content: "*";
    color: red;
  }

  span {
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
  }

  input:invalid[focused="true"] {
    border: 1px solid red;
  }

  input:invalid[focused="true"] ~ span {
    display: block;
  }
}
