/* TableStyles.css */

.rdt_TableRow:hover {
  cursor: pointer;
}

.table-contents {
  .data-table__cell {
    cursor: pointer;
  }

  .tabs-header {
    display: flex;
    justify-content: space-between;
    margin: 5px 2px;

    .status-tabs {
      display: flex;
      gap: 20px;
      align-items: center;
      font-size: 13px;
      font-weight: 500;
      line-height: 150%;
      padding: 10px 14px !important;
      cursor: pointer;
      color: #405189;
      .tab {
        padding-bottom: 10px;
      }
      .active {
        // color: #27ae60;
        border-bottom: 2px solid #405189;
      }
    }
    .right-tab{
      display: flex;
      gap: 20px;
      .search-bar{
        input{
          padding-bottom: 10px;
        }
      }
    }
  }
  .rdt_TableHead {
    position: relative;
    z-index: 0;
  }
  .rdt_TableHeadRow {
    background-color: #ebfcff;
    position: relative;
    z-index: 0;
  }
  .rdt_TableBody {
    position: relative;
    z-index: 1;
    min-height: calc(100dvh - 315px);
  }
  div:has(.rdt_Pagination) {
    position: relative;
    z-index: 0;
  }
  .rdt_Pagination {
    position: relative;
    z-index: 0;
  }

  .status-indicator.scheduled {
    background-color: #f9e79f;
  }

  .status-indicator.active {
    background-color: #f7dc6f;
  }

  .status-indicator.completed {
    background-color: #d7bde2;
  }

  .status-indicator.submitted {
    background-color: #a9cce3;
  }

  .status-indicator.approved {
    background-color: #a9dfbf;
  }

  .status-indicator.published {
    background-color: #aed6f1;
  }
  .status {
    text-transform: uppercase;
    width: 100%;
    text-align: center;
  }
  .status.assessment {
    color: #3498db; /* Light Blue */
    background-color: #ebf5fb; /* Very Light Blue */
    border: 1px solid #b8daff; /* Light Blue Border */
    padding: 5px 10px;
    border-radius: 4px;
  }
  .status.registered {
    color: #2ecc71; /* Green */
    background-color: #e9f7ef; /* Light Green */
    border: 1px solid #d4edda; /* Green Border */
    padding: 5px 10px;
    border-radius: 4px;
  }
  .status.enrolled {
    color: #8e44ad; /* Purple */
    background-color: #f5eef8; /* Light Purple */
    border: 1px solid #d6cde6; /* Purple Border */
    padding: 5px 10px;
    border-radius: 4px;
  }
  .status.learning {
    color: #17a2b8; /* Teal */
    background-color: #e3f7fa; /* Light Teal */
    border: 1px solid #b1e1e6; /* Teal Border */
    padding: 5px 10px;
    border-radius: 4px;
  }
  .status.inprogress {
    color: #fd7e14; /* Orange */
    background-color: #fff5e6; /* Light Orange */
    border: 1px solid #fdd49a; /* Orange Border */
    padding: 5px 10px;
    border-radius: 4px;
  }
  .status.scheduled {
    color: #f1c40f;
    background-color: #fcf8e3;
    border: 1px solid #faebcc;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .status.incomplete,
  .status.pending {
    color: orange;
    background-color: #fcf8e3;
    border: 1px solid #faebcc;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .status.active,
  .status.rescheduled,
  .status.paid {
    color: #528830;
    background-color: #f4faf0;
    border: 1px solid #528830;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .status.inactive,
  .status.failed {
    color: #ba1263;
    background-color: #fdecf5;
    border: 1px solid #ba1263;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .status.completed {
    color: #528830;
    background-color: #f4faf0;
    border: 1px solid #528830;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .status.cancelled {
    color: #bb1a25;
    background-color: #fef2f3;
    border: 1px solid #bb1a25;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .status.submitted,
  .status.refunded {
    color: #2980b9;
    background-color: #d6eaf8;
    border: 1px solid #aed6f1;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .status.approved {
    color: #27ae60;
    background-color: #eafaf1;
    border: 1px solid #d6e9c6;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .status.rejected {
    color: #e74c3c;
    background-color: #f7e6e6;
    border: 1px solid #e74c3c;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .status.published {
    color: #3498db;
    background-color: #eaf3fb;
    border: 1px solid #bcdff1;
    padding: 5px 10px;
    border-radius: 4px;
  }
}

.table-contents > div {
  position: relative;
  z-index: 0;
}
