/* Modal.css */
.modal-backdrop2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-content2 {
  background-color: white;
  border-radius: 8px;
  width: 80%;
  margin: auto;
}

.modal-content2-sm {
  width: 35%;
  margin: auto;
  margin-top: 5%;
}

.modal-header2 {
  display: flex;
  border-radius: 8px 8px 0 0;
  justify-content: space-between;
  align-items: center;
  background: #dff0fa;
  padding: 8px 16px;
}

.modal-header2 h4 {
  font-size: 20px;
  line-height: 24px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body2 {
  margin-top: 10px;
  padding: 0 16px;
  font-size: 1rem;
}
