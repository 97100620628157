.play--button {
    border: none;
    background: transparent;
  }
  .play--button svg {
    max-width: 30px;
    max-height: 30px;
  }
  
  .spotlight--viva--name {
    margin-top: 120px;
    color: #fff;
    text-align: center;
    font-family: "Opun Mai";
    font-size: clamp(1.25rem, 1.1rem + 0.75vw, 2rem);
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
  
  .spotlight--items--wrapper {
    max-width: 1046px;
    margin-inline: auto;
    margin-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
  
  .spotlight--item--container {
    width: max-content;
    max-width: 264px;
    padding: 12px;
    border-radius: 8px;
    background: #ebfcff;
  }
  
  .spotlight--item--author {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .spotlight--item--content h4 {
    color: var(--black-shade-2);
    font-family: Nunito;
    font-size: clamp(1rem, 0.9rem + 0.5vw, 1.5rem);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .spotlight--item--content p {
    color: var(--black-shade-2);
    font-family: "Proxima Nova";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .spotlight--item--audio {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
  }
  
  .spotlight--item--audio h4 {
    color: var(--black-shade-2);
    font-family: "Instrument Sans";
    font-size: clamp(0.875rem, 0.8rem + 0.375vw, 1.25rem);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.03px;
  }
  .audio--wave svg {
    max-width: 180px;
  }
  
  @media screen and (min-width: 578px) {
    .spotlight--items--wrapper {
      max-width: 1046px;
      margin-inline: auto;
      margin-bottom: 100px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 40px;
    }
  
    .spotlight--item--container {
      width: max-content;
      max-width: 503px;
      padding: 12px;
      border-radius: 8px;
      background: #ebfcff;
    }
    .play--button svg {
      max-width: 60px;
      max-height: 60px;
    }
    .audio--wave svg {
      max-width: 300px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .spotlight--items--wrapper {
      max-width: 1046px;
      margin-inline: auto;
      margin-bottom: 100px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 40px;
    }
  
    .spotlight--item--container {
      width: max-content;
      max-width: 600px;
      padding: 12px;
      border-radius: 8px;
      background: #ebfcff;
    }
    .play--button svg {
      max-width: 40px;
      max-height: 40px;
    }
    .audio--wave svg {
      max-width: 138px;
    }
  }
  @media screen and (min-width: 1024px) {
    .spotlight--items--wrapper {
      max-width: 1046px;
      margin-inline: auto;
      margin-bottom: 100px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 40px;
    }
  
    .spotlight--item--container {
      width: max-content;
      max-width: 400px;
      padding: 12px;
      border-radius: 8px;
      background: #ebfcff;
    }
    .play--button svg {
      max-width: 60px;
      max-height: 60px;
    }
    .audio--wave svg {
      max-width: 138px;
    }
  }
  @media screen and (min-width: 1200px) {
    .spotlight--items--wrapper {
      max-width: 1046px;
      margin-inline: auto;
      margin-bottom: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 40px;
    }
  
    .spotlight--item--author {
      display: flex;
      flex-wrap: nowrap;
      gap: 8px;
    }
  
    .spotlight--item--container {
      width: max-content;
      max-width: 264px;
      padding: 12px;
      border-radius: 8px;
      background: #ebfcff;
    }
    .play--button svg {
      max-width: 40px;
      max-height: 40px;
    }
    .audio--wave svg {
      max-width: 138px;
    }
  }