.course-details-page {
    .tabs.page-tab {
        display: flex;
        background-color: transparent;
        padding: 0;
        border: unset;
        .tab {
          padding: 10px 20px;
          cursor: pointer;
          border-radius: 8px 8px 0px 0px;
          color: #405189;
          font-size: 16px;
          font-weight: 400;
          line-height: 120%;
          &.tab-active {
            font-weight: bold;
            background-color: #fff;
          }
        }
    }
    .tab-content {
        overflow-y: scroll;
        height: calc(100vh - 166.8px);
        // background-color: #fff; 
        .assessment-content{
            background-color: #fff;
            // height: calc(100vh - 182.5px);
            .title{
                border-bottom: 1px solid #ddd;
            }
            .video-container{
                padding-top: 20px;
                width: 100%;
                display: flex;
                justify-content: center;
                video {
                    height: 400px;
                    width: 100%;
                }
            }
            .vid-approve-buttons{
                padding-top: 12px;
                display: flex;
                justify-content: center;
                gap: 30px;
            }
            
        }
        .viva{
            .viva-element{
                border-radius: 12px;
                border: 1px solid var(--Neutral-Grey-100, #F6F7F9);
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.04);
                padding: 20px;
                margin: 12px;
            }
            .location-container{
                .viva-assessment-form{
                    margin-top: 6px;
                    display: flex;
                    gap: 20px;
                    label {
                        margin: 6px;
                    }
                    input {
                        margin-left: 10px;
                        margin-top: 8px;
                    }
                    .input-link-wrapper{
                        flex-grow: 1;
                    }
                    .input-link{
                        width: 100%;
                        border-color: #CED4DA;
                    }
                    .input-link:focus{
                        box-shadow: none;
                    }
                }

            }
        }
        .scheduled-viva-screen{
            .scheduled-viva{
                display: flex;
                justify-content: center;
                text-align: center;
                flex-direction: column;
                margin: 2rem 0;

                .viva-schedule-gif{
                    img{
                        height: 120px;
                        width: 120px;
                    }
                }

                .viva-schedule-title{
                    color: var(--Gren-Green-200---Base, #72BE44);
                    text-align: center;
                    font-family: "Instrument Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: .5rem;
                }

                .description{
                    color: var(--Neutral-Grey-400, #949BB3);
                    text-align: center;
                    font-family: "Instrument Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .schedule-viva-card{
                    width: fit-content;
                    margin: 1.25rem auto;
                    display: flex;
                    padding: 12px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 12px;
                    border-radius: 8px;
                    border: 1px solid var(--Gery-lighter, #ECEDF2);

                    .viva-card-items{
                        font-weight: 600;
                    }
                    .meeting-link{
                        color: #006CF0;
                        text-decoration: underline;
                    }
                    
                }
            }
        }
        .viva.disabled{
            opacity: 0.8;
            pointer-events: none;
        }
      }
      .modal-content{
        .modal-header{
            display: flex;
            justify-content: space-between;
            background-color: #DFF0FA;
            height: 48px;

        }
          .close-modal:hover{
              cursor: pointer;

          }

          .reject-modal-body {
              display: flex;
              justify-content: center;
              flex-direction: column;
              // input{
              //     height: 200px;
              // }
          }
      }
}