@import "./variable.scss";

* {
  box-sizing: border-box;
  margin: 0;
}
body {
  height: 100vh;
}
a {
  text-decoration: none;
  color: inherit;
}
a:visited {
  color: inherit;
}
.container-fluid {
  display: flex;
  flex-direction: column;
}
.page-content {
  height: calc(100% - 118px);
}
.tabs {
  display: flex;
  justify-content: flex-start;
  background-color: $white;
  padding: 0 8px;
  border-bottom: 1px solid #e9ebec;
  width: 100%;
  .tab {
    font-size: 13px;
    font-weight: 500;
    line-height: 150%;
    padding: 10px 14px !important;
    cursor: pointer;
    color: #405189;
    border-bottom: 2px solid transparent;
    transition: color 0.3s, border-bottom 0.3s;
    padding: 12px 16px;
    &.active {
      color: #405189;
      border-bottom: 2px solid #405189;
    }
    &:hover {
      color: #405189;
    }
  }
}

.card-lg {
  height: calc(100vh - 190px);
  // height: 100vh;
  overflow: auto;
}

.control-dots {
  display: none !important;
}
