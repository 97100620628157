.activity-details-page {
  margin: 0 !important;
  height: 100%;
  width: 100%;
  gap: 12px;
  display: flex;
  .activity-left {
    width: 65%;
    &.showRight {
      width: 100%;
    }
  }

  .tabs{
    display: flex;
    background-color: transparent;
    padding: 0;
    border: unset;
    .inner-tab {
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 8px 8px 0px 0px;
      color: #405189;
      font-size: 13px;
      font-weight: 500;
      line-height: 150%;
      &.active {
        font-weight: bold;
        background-color: #fff;
        border-bottom: 1px solid #405189;
      }
    }
  }

  .inner-tab:last-child{
    margin-left: auto;
  }

  .viva-accordion-container{
    .viva-preview-container {
      position: relative;
      .viva-checkbox {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
    .vid-container {
      position: relative;
      display: inline-block;
    }
    .img-checkbox {
      position: absolute;
      top: 5px;
      right: 5px;
    }
    .status-logo{
      position: absolute;
      top: 80px;
      right: 150px;
      background-color: white;
    }
    .status-logo-viva{
      position: absolute;
      top: 40px;
      right: 140px;
    }
  }

  .activity-right {
    width: 35%;
  }
  .tabs.page-tab {
    display: flex;
    background-color: transparent;
    padding: 0;
    border: unset;
    .tab {
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 8px 8px 0px 0px;
      color: #405189;
      font-size: 16px;
      font-weight: 400;
      line-height: 120%;
      &.tab-active {
        font-weight: bold;
        background-color: #fff;
      }
    }
  }
  .tab-content {
    background-color: #fff;
    &.evidence-content {
      position: relative;
      .approved-btn-container {
        position: absolute;
        padding: 12px;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        margin-left: auto;
        display: flex;
        justify-content: end;
      }
    }
  }
  .inner-tab-content {
    padding: 12px;
    max-height: calc(100vh - 226px);
    min-height: calc(100vh - 226px);
    height: 100%;
    overflow-y: scroll;
    &.show-button {
      padding-bottom: 70px;
    }
  }
  .media-item {
    position: relative;
    width: 120px;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
    }
    .icon-overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.3s ease;
      img {
        width: 20px;
        height: 20px;
        color: #fff;
      }
    }
    &:hover .icon-overlay {
      opacity: 1;
    }
  }
  .logbook-media-item {
    border: 0.5px solid #d6d8e1;
    border-radius: 12px;
    padding: 12px;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    .logbookAudio-media-item {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      cursor: pointer;
      .viva-preview-container{
        position: relative;
        display: inline-block;
      }
    }
    .img-container {
      position: relative;
      display: inline-block;
    }
    .img-container img {
      display: block;
      width: 100px;
      height: 100px;
    }
    .img-checkbox {
      position: absolute;
      top: 5px;
      right: 5px;
    }
    .status-logo{
      position: absolute;
      top: 40px;
      right: 40px;
      background-color: white;
    }
    .status-logo-viva{
      position: absolute;
      top: 50%;
      right: 50%;
    }
    .vid-container {
      position: relative;
      display: inline-block;
    }
    .vid-container video {
      display: block;
      width: 100px;
      height: 100px;
    }

    // svg{
    //   position: absolute;
    //   top: 40px;
    //   right: 40px;
    // }
  }
  .media-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  .logbook-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 60px;
  }
  .viva-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 60px;
    font-size: 16px;
    .logbook-media-item {
      border: 0.5px solid var(--Neutral-Grey-300, #d6d8e1);
      border-radius: 12px;
      padding: 12px;
      color: #000;
      font-size: 16px;
      font-weight: 600;
      .logo-and-name {
        display: flex;
      }
      .viva-preview-container {
        position: relative;
        .viva-checkbox {
          position: absolute;
          top: 5px;
          right: 5px;
        }
      }
    }
    .viva-tile-container {
      display: flex;
      flex-direction: row;
    }
  }
  .deleted-media-wrapper {
    background-color: #fff;
    margin-top: 41px;
    display: flex;
    flex-direction: column;
    position: relative;
    .delete-header {
      border-bottom: 1px solid #ecedf2;
      display: flex;
      padding: 8px 12px;
      justify-content: space-between;
      align-items: center;
      .title {
        color: #0c2033;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.02px;
        margin: 0;
      }
    }
    .deleted-media {
      padding: 12px;
      max-height: calc(100vh - 226px);
      min-height: calc(100vh - 226px);
      height: 100%;
      overflow-y: scroll;
      &.show-button {
        margin-bottom: 70px;
        max-height: calc(100vh - 296px);
        min-height: calc(100vh - 296px);
      }
      .subTitle {
        color: #222;
        font-size: 16px;
        font-weight: 800;
        text-transform: capitalize;
      }
      .media-data {
        margin-bottom: 12px;
      }
      .media-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
      }
    }
    .media-item {
      width: 92px;
      height: 92px;
    }
    .deleted-btn-container {
      position: absolute;
      padding: 12px;
      bottom: 0;
      width: 100%;
      background-color: #fff;
      button {
        width: 100%;
      }
    }
  }
  .close-btn {
    padding: 0 5px;
  }
  .highlight-media{
    border: 4px solid yellow;
  }
}


.viva-all-media-container{
  .accordion-title{
    font-size: 16px;
    font-weight: 600;
  }
  .question-container{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }
}