.ticket-action-button {
  width: 96px;
  height: 36px;
  background-color: #f6921e !important;
  border-color: #f6921e !important;
  border-bottom: 3px solid rgba(215, 119, 9, 1) !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 19.5px !important;
  text-align: left !important;
  text-decoration-skip-ink: none !important;
  color: #fff !important;
  border-radius: 8px 0 0 8px !important;
  text-decoration: none !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease, transform 0.2s ease !important;
}

.ticket-action-dropdown-button {
  width: 32px;
  height: 36px;
  background-color: #f6921e !important;
  border-color: #f6921e !important;
  border-bottom: 3px solid rgba(215, 119, 9, 1) !important;
  border-radius: 0 8px 8px 0 !important;
}

.ticket-action-button:hover {
  background-color: #cc750c !important; /* Slightly darker shade for hover */
  // transform: translateY(-2px);
}

.ticket-action-button:active {
  background-color: #cc750c; /* Darker shade for active state */
  transform: scale(0.98); /* Reset the hover lift effect */
}

.ticket-action-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(246, 146, 30, 0.5); /* Adds focus ring for accessibility */
}

.ticket-action-select {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 19.5px !important;
  text-align: left !important;
  text-decoration-skip-ink: none !important;
  border-radius: 8px !important;
  --bs-dropdown-min-width: 100% !important;
}

.tickets-description {
  max-width: 340px;
}

@media screen and (max-width: 1599px) {
  .tickets-description {
    max-width: 260px !important;
  }
}
