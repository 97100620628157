@import "../../assets/scss/variable.scss";
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: $white;
  height: $subHeaderHeight;
  border-bottom: 1px solid #ddd;
}
.activity-section {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 120%;
  color: #495057;
}
.breadcrumb-section {
  display: flex;
  align-items: center;
  gap: 8px;

  .breadcrumb {
    font-size: 13px;
    font-weight: 500;
    line-height: 150%;
    color: #212529;
    margin: 0 !important;

    &:last-child {
      color: #878A99;
    }
  }
}

  