.button {
  padding: 10px 40px;
  border: 1px solid #ea983e !important;
  border-bottom: 3px solid #dc7e18 !important;
  border-radius: 0.5rem !important;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #dc7e18 !important;
  }
}

.button:active {
  transform: scale(0.98);
}

.button.cancel {
  border: 1px solid #ea983e;
  background-color: #fff;
  color: #ea983e;
  &:hover {
    border: 1px solid #dc7e18;
    background-color: #e6dddd !important;
  }
}

// .button.btn-danger:hover {
//   background-color: initial !important;
// }

.button:disabled {
  background: gray !important;
  cursor: not-allowed !important;
}
