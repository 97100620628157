.calendar-container {
  max-width: 24rem;
  height: 24rem;
  margin-bottom: 2rem;
}

.calendar-day-grid,
.calendar-date-grid {
  display: grid;
  margin: 1rem auto;
  grid-template-columns: repeat(7, 1fr);
  place-items: center;
}

.calendar-day-grid > h6 {
  color: gray;
}

.calendar-date-grid > div {
  place-items: center;
  border-top: 1px solid lightgray;
  padding: 1rem;
}

.not-current-month-date {
  color: lightgray;
}
