.curriculum-details {
  background-color: white;
  height: calc(100vh - 183px);
  display: flex;
  flex-direction: column;
  // align-items: ;
  .activity-save-btn {
    margin: auto 1rem 1rem auto;
  }
}

.add-curriculum-form {
  height: 100%;
  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    .activity-save-btn {
      margin-top: auto;
    }
  }
}
