@import "../../assets/scss/variable.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: $white;
  height: $headerHeight;
  border-bottom: 1px solid #ddd;
}
  .school-logo {
    display: flex;
    align-items: center;
  }
  .logo-image {
    height: 40px;
    width: auto;
  }
  .header-icons {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .profile-info {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #F6F7F9;
    padding: 7px 14px;
    border-radius: 36px;
    position: relative;
    cursor: pointer;
  }
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .profile-name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  .tooltip {
    position: absolute;
    top: 60px; // Ensure this is clear of the profile image.
    width: 100px;
    right: 0;
    background-color: #ffffff; // White background for visibility
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 999999 !important; // Keep it above other elements
    color: #333; // Dark text color for contrast
    opacity: 100 !important;
  }
  
  
  
  .tooltip button {
    background: none;
    border: none;
    color: #EA983E;
    cursor: pointer;
  }
  