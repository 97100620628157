.tabs.page-tab {
    display: flex;
    background-color: transparent;
    padding: 0;
    border: unset;
    .tab {
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 8px 8px 0px 0px;
      color: #405189;
      font-size: 16px;
      font-weight: 400;
      line-height: 120%;
      &.tab-active {
        font-weight: bold;
        background-color: #fff;
      }
    }
  }
  