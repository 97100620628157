@import "../../assets/scss/variable.scss";

.main-layout {
  .inner-main-layout{
    position: relative;
  }
  .download-indicator-container{
    position: absolute;
    display: flex;
    justify-content: center;
    right: 50px;
    bottom: 30px;
    width: auto;
    padding: 3px 10px;
  height: 30px;
  background-color: black !important;
  color: white;
  
  border-radius: 5px;
  font-size: 14px;
  }
  .page-content {
    background-color: #ECEDF2;
    padding: 16px;
    max-height: calc(100vh - ($headerHeight + $subHeaderHeight));
    overflow-y: scroll;
    height: 100%;
  }

  .page-content:has(.course-details-page){
    overflow-y: hidden;
    padding-right: 0px;
  }
}
