// components/CustomAlert.scss
.custom-alert {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    padding: 16px;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 250px;
    max-width: 400px;
  
    &-message {
      flex-grow: 1;
      margin-right: 12px;
    }
  
    &-close {
      background: transparent;
      border: none;
      color: inherit;
      font-size: 18px;
      cursor: pointer;
    }
  
    &.info {
      background-color: #2196f3;
    }
  
    &.success {
      background-color: #4caf50;
    }
  
    &.warning {
      background-color: #ff9800;
    }
  
    &.error {
      background-color: #f44336;
    }
  }
  