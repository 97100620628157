.preview-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.base-modal{
    z-index: 1056;
}

.slide > div{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}