.ticket-action-select {
  background-color: #f6921e;
  border-bottom: 3px solid rgba(215, 119, 9, 1);
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #fff;
  border-radius: 8px;
}

.assessment-content {
  .table-contents {
    .rdt_TableBody {
      position: relative;
      z-index: 1;
      min-height: unset !important;
    }
  }
}

.viva-listing {
  .table-contents {
    .rdt_TableBody {
      position: relative;
      z-index: 1;
      min-height: 120px !important;
    }
  }
}
