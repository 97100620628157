.navigation {
  background-color: #2b2b2b;
  color: #fff;
  height: 100vh;
  padding: 20px;

  .logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .menu {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      margin: 15px 0;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.012px;
      font-style: normal;
      padding-bottom: 10px;

      svg {
        width: 20px; // Adjust the size as needed
        height: 20px;
        margin-right: 15px;
      }

      .submenu {
        list-style: none;
        padding-left: 20px;
        padding-inline-start: 0;
        margin-top: 10px;

        li {
          display: flex;
          align-items: center;

          svg {
            width: 15px; // Adjust the size as needed
            height: 15px;
            margin-right: 5px;
          }
        }
      }
    }

    .submenu {
      padding: 0;

      li {
        display: flex;
        padding-left: 40px;
      }
    }

    li.active {
      a {
        color: #f6921e !important;
      }
    }
  }
}
